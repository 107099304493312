import * as React from "react"

import { FaTwitter } from "react-icons/fa"


interface Props {
  name: string,
}

export const TwitterLink: React.FC<Props> = ( { name }) => {
  return (
    <a href={"https://twitter.com/" + name} target="_blank" style={{ display: "inline", color: 'dodgerblue' }}>
      <span>
        <FaTwitter style={{display: "inline"}}/>@{name}
      </span>
    </a>
  );
};

export default TwitterLink
