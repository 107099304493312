import * as React from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify';
import { FaClipboard } from "react-icons/fa"


interface Props {
  label: string,
  text: string,
  message: string,
}

class CopyButton extends React.Component<Props> {

  constructor(Props) {
    super(Props);
  }

  handleClick() {
    toast.info( this.props.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    } )
  }

  render() {
    return (
      <CopyToClipboard text={this.props.text} onCopy={ () => this.handleClick() }>
        <div className="copy-button" style={{ width: '10rem', cursor: 'pointer' }}>
          <span>
            <FaClipboard style={{display: "inline"}} />
            &thinsp;
            {this.props.label}
          </span>
        </div>
      </CopyToClipboard>
    );
  }
}

export default CopyButton
