import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Margin from "../components/margin"
import Leading from "../components/leading"
import SectionLine from "../components/section-line"
import CopyButton from "../components/copy-button"
import TwitterLink from "../components/twitter-link"

import { FaTwitter, FaTelegram, FaFacebook, FaClipboard } from "react-icons/fa"


const IndexPage: React.FC = () => (
  <Layout>
    <Seo />

    <div className="container">
      <div className="container-text">
        <div className="box box-plain">
          <p>
            1STEP は Nori (<TwitterLink name="nori_crypto" />) による日本のCardanoステークプールです。
            経験豊富なIT技術者による安定稼働でCardanoネットワークに貢献するとともに、
            運用利益を資金としたCardanoに関する情報発信・解説コンテンツ作成によって
            Cardanoコミュニティへの貢献を目指します。<br />
            <br />
            1STEP is a Japanese Cardano Stake Pool managed by Nori (<TwitterLink name="nori_crypto" />).
            Operated by experienced IT engineers.
            We provide not only a reliable stake pool but also a Japanese YouTube channel
            explains technical information about Cardano.
          </p>
        </div>
      </div>
      <div className="box" style={{ maxWidth: '600px' }}>
        <span className="ticker">
          Ticker: <span className="cousine">1STEP</span>
        </span>
        <Margin height="0.5rem"/>
        <CopyButton label="Copy Ticker" text="1STEP" message="Ticker Copied!"/>
      </div>
      <div className="hide-small">
        <a target="_blank" href="https://cexplorer.io/pool/pool1ar6nsa2uzng70uf8fdrttu5jhgxzgu2f4za8h845zr435nfcruw/delegate#instant" style={{ padding: '10px', paddingLeft: '20px', borderRadius: '11px', font: "normal bold 22px/1 'Open Sans', sans-serif", color: 'white', textAlign: 'center', background: "url('https://img.cexplorer.io/s/ada.svg') no-repeat #15d798 10px", paddingLeft: '40px' }}><span>委任する <small>Delegate</small></span></a>
      </div>
    </div>
    <div style={{ width: "90%", maxWidth: "800px", margin: "auto", marginTop: "3rem" }}>
      <iframe id="adapools" className="hide-small" width="100%" height="320" frameborder="0" src="https://img.cexplorer.io/w/widget.html?pool=pool1ar6nsa2uzng70uf8fdrttu5jhgxzgu2f4za8h845zr435nfcruw&theme=dark"><a href="https://cexplorer.io/pool/pool1ar6nsa2uzng70uf8fdrttu5jhgxzgu2f4za8h845zr435nfcruw">pool detail on cexplorer.io</a></iframe>
      <iframe id="adapools-small" className="hide-large" style={{ width: '100%', height: '60px', border: 'solid 1px gray', fontSize: '80%' }} frameborder="0" src="https://img.cexplorer.io/w/widget-wide.html?pool=pool1ar6nsa2uzng70uf8fdrttu5jhgxzgu2f4za8h845zr435nfcruw&theme=light"><a href="https://cexplorer.io/pool/pool1ar6nsa2uzng70uf8fdrttu5jhgxzgu2f4za8h845zr435nfcruw">pool detail on cexplorer.io</a></iframe>
    </div>

    <div className="hide-small" style={{ marginBottom: '1rem' }}></div>

    <div className="container">
      We are a member of<br />
      <a href="https://spojapanguild.net/" target="_blank" style={{ display: "inline" }}>
        <StaticImage
            src="../images/sjg-yoko-logo-467.png"
            width={300}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="SJG Logo"
            style={{ margin: "auto", width: "90%", maxWidth: "300px", border: 'solid 1px gray' }}
          />
      </a>
    </div>

    <Margin height="3rem"/>
    <SectionLine />

    <div className="container">
      <Leading main="キャンペーン" sub="Pool Promotions" />
      <div className="box campain-box campain-box-expired">
        <strong style={{ marginBottom: "0.2rem" }}>【終了】継続委任キャンペーン</strong>
        <br />
        <div className="container-text">
          1,000ADA以上を10エポック継続してご委任された方へ最大1,000ADAプレゼント！
        </div>
        <Margin height="0.5rem"/>
        <div className="link-wrapper">
          <Link to="/campaign" style={{ display: "inline" }}>
            <div className="campain-link" style={{ width: '6rem' }}>
              <span>詳細</span>
            </div>
          </Link>
          <Link to="/reward" style={{ display: "inline" }}>
            <div className="campain-link" style={{ width: '6rem' }}>
              <span>報酬確認</span>
            </div>
          </Link>
        </div>
      </div>
      {/*
      <Margin height="1.0rem"/>
      <div className="box campain-box">
        <strong style={{ marginBottom: "0.2rem" }}>大口歓迎キャンペーン</strong>
        <br />
        <div className="container-text">
          100万ADA以上の委任でブロック生成エポックの度に100～300ADAプレゼント！
        </div>
        <Margin height="0.5rem"/>
        <div className="link-wrapper">
          <Link to="/campaign1" style={{ display: "inline" }}>
            <div className="campain-link" style={{ width: '12rem' }}>
              <span>詳細</span>
            </div>
          </Link>
          <a style={{ display: "inline" }}>
            <div className="campain-link" style={{ width: '6rem', background: 'gray' }}>
              <span>報酬確認</span>
            </div>
          </a>
        </div>
      </div>
      */}
    </div>

    <Margin height="0.5rem"/>
    <SectionLine />

    <div className="container">
      <Leading main="YouTubeチャンネル" sub="Our YouTube Channel"/>
      <div className="box ycc-box">
        <a href="https://www.youtube.com/channel/UCnO3pdhaLExEToa9kM_lCBw" target="_blank" style={{ display: "inline" }}>
          <StaticImage
              src="../images/ycc_banner.png"
              width={400}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="YCC Logo"
              style={{ margin: "auto", width: "90%", maxWidth: "400px" }}
            />
        </a>
        <Margin height="0.5rem"/>
        <div className="container-text">
          ゆっくりCardanoチャンネルでは、ゆっくり霊夢＆魔理沙がCardanoについてなるべくわかりやすく解説します。<br />
        </div>
      </div>
    </div>

    <Margin height="0.5rem"/>
    <SectionLine />

    <div className="container">
      <Leading main="ソーシャルアカウント" sub="Our Social accounts"/>
      <div className="social-wrapper">
        <a href="https://twitter.com/1step_cardano" target="_blank" style={{ color: 'dodgerblue' }}>
          <h1 style={{ display: "inline" }}>
            <FaTwitter className="sns-icon" style={{display: "inline"}}/>
          </h1>
        </a>
        <a href="https://t.me/cardano_1step" target="_blank" style={{ color: 'dodgerblue' }}>
          <h1 style={{ display: "inline" }}>
            <FaTelegram className="sns-icon" style={{display: "inline"}}/>
          </h1>
        </a>
        <a href="https://www.facebook.com/nori.crypto/" target="_blank" style={{ color: 'dodgerblue' }}>
          <h1 style={{ display: "inline" }}>
            <FaFacebook className="sns-icon" style={{display: "inline"}}/>
          </h1>
        </a>
      </div>
    </div>

    <Margin height="0.5rem"/>
    <SectionLine />

    <div className="container">
      {/*
      <p>
        <Link to="/page-2" style={{ display: "inline" }}>
          <strong>お問い合わせ - Contact</strong>
        </Link>
      </p>
      */}
      <p>
        <Link to="/disclaimer" style={{ display: "inline" }}>
          <strong>免責事項 - Disclaimer</strong>
        </Link>
      </p>
    </div>

  </Layout>
)

export default IndexPage
